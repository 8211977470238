import React from 'react';
import PropTypes from 'prop-types';
import AnalysisFAQ from './analysisFAQ.jsx';
const axios = require('axios');

/**
 * Format float
 *
 * @param {float} x - A number
 *
 * @return {x} A formatted string
 */
function formatFloat(x) {
  if (x === 0.0) {
    return '0.00%';
  }

  const minimumFractionDigits = Math.max(0, -Math.floor(Math.log10(Math.abs(x))));

  return Number(x).toLocaleString(undefined, {
    style: 'percent',
    minimumFractionDigits: minimumFractionDigits,
  });
}

/**

 */
class ExperimentGroup extends React.Component {
  /**
   * Render row of contingency table.
   *
   * @return {JSX} The row
   */
  render() {
    const groupName = this.props.groupName;
    const successes = this.props.successes;
    const trials = this.props.trials;
    let successRate;
    if (successes !== '' && trials !== '' && trials !== '0') {
      try {
        successRate = formatFloat(
          parseInt(successes.replace(/,/g, '')) / parseInt(trials.replace(/,/g, '')),
        );
      } catch (err) {
        successRate = '';
      }
    } else {
      successRate = '';
    }

    let deleteButton;
    if (this.props.deletable) {
      deleteButton = (
        <input
          type="button"
          value="Delete"
          onClick={(e) => {
            this.props.deleteRow(this.props.idx);
          }}
        />
      );
    } else {
      deleteButton = '';
    }

    return (
      <tr>
        <td>
          <input
            style={{
              width: 80,
            }}
            type="text"
            value={groupName}
            onChange={(e) => {
              this.props.onGroupNameChange(this.props.idx, e.target.value);
            }}
          />
        </td>
        <td>
          <input
            style={{
              width: 60,
              textAlign: 'right',
            }}
            type="text"
            value={this.props.successes}
            onChange={(e) => {
              this.props.onNumberChange(this.props.idx, 'successes', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur(this.props.idx, 'successes', e.target.value);
              this.props.completeTable(this.props.idx);
            }}
          />
        </td>
        <td>
          <input
            style={{
              width: 60,
              textAlign: 'right',
            }}
            type="text"
            value={this.props.failures}
            onChange={(e) => {
              this.props.onNumberChange(this.props.idx, 'failures', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur(this.props.idx, 'failures', e.target.value);
              this.props.completeTable(this.props.idx);
            }}
          />
        </td>
        <td>
          <input
            style={{
              width: 60,
              textAlign: 'right',
            }}
            type="text"
            value={this.props.trials}
            onChange={(e) => {
              this.props.onNumberChange(this.props.idx, 'trials', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur(this.props.idx, 'trials', e.target.value);
              this.props.completeTable(this.props.idx);
            }}
          />
        </td>
        <td align="right">{successRate}</td>
        <td>{deleteButton}</td>
      </tr>
    );
  }
}

ExperimentGroup.propTypes = {
  idx: PropTypes.number,
  groupName: PropTypes.string,
  successes: PropTypes.string,
  failures: PropTypes.string,
  trials: PropTypes.string,
  deletable: PropTypes.bool,
  deleteRow: PropTypes.func,
  onNumberChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
  completeTable: PropTypes.func,
  onGroupNameChange: PropTypes.func,
};

/**

 */
class TotalValues extends React.Component {
  /**
   * Render total values row.
   *
   * @return {JSX} The row
   */
  render() {
    const successes = this.props.totalSuccesses.replace(
      /(\d)(?=(\d{3})+(?!\d))/g,
      '$1,',
    );
    const failures = this.props.totalFailures.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    const trials = this.props.totalTrials.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

    let successRate;
    if (successes !== '' && trials !== '' && trials !== 0) {
      successRate = formatFloat(this.props.totalSuccesses / this.props.totalTrials);
    } else {
      successRate = '';
    }

    return (
      <tr>
        <td>Total</td>
        <td align="right">{successes}</td>
        <td align="right">{failures}</td>
        <td align="right">{trials}</td>
        <td align="right">{successRate}</td>
        <td></td>
      </tr>
    );
  }
}

TotalValues.propTypes = {
  totalSuccesses: PropTypes.string,
  totalFailures: PropTypes.string,
  totalTrials: PropTypes.string,
};

/**

 */
class AdvancedSettings extends React.Component {
  /**
   * Render advanced settings.
   *
   * @return {JSX} The advanced settings
   */
  render() {
    const alpha = this.props.alpha;
    const nullLift = this.props.nullLift;
    const liftType = this.props.liftType;
    return (
      <table>
        <tr>
          <td>Type-I Error Threshold (&alpha;)</td>
          <td>
            <input
              className="defaultTextbox"
              type="text"
              value={alpha}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('alpha', e.target.value);
              }}
              onBlur={(e) => {
                this.props.onAdvancedSettingComplete('alpha', e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Lift Under Null Hypothesis</td>
          <td>
            <input
              className="defaultTextbox"
              type="text"
              value={nullLift}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('nullLift', e.target.value);
              }}
              onBlur={(e) => {
                this.props.onAdvancedSettingComplete('nullLift', e.target.value);
              }}
            />
            %
          </td>
        </tr>
        <tr>
          <td>Lift Type</td>
          <td>
            <select
              onChange={(e) => {
                this.props.onAdvancedSettingChange('liftType', e.target.value);
              }}
            >
              <option
                value="relative"
                selected={liftType === 'relative' ? 'selected' : ''}
              >
                Relative
              </option>
              <option
                value="absolute"
                selected={liftType === 'absolute' ? 'selected' : ''}
              >
                Absolute %
              </option>
              <option
                value="incremental"
                selected={liftType === 'incremental' ? 'selected' : ''}
              >
                Incr. Successes
              </option>
            </select>
          </td>
        </tr>
      </table>
    );
  }
}

AdvancedSettings.propTypes = {
  alpha: PropTypes.number,
  nullLift: PropTypes.number,
  liftType: PropTypes.string,
  onAdvancedSettingChange: PropTypes.func,
  onAdvancedSettingComplete: PropTypes.func,
};

/**

 */
class AnalysisResults extends React.Component {
  /**
   * Render the results table
   *
   * @return {JSX} The results table
   */
  render() {
    const results = this.props.results;
    const alpha = this.props.alpha;
    const confidenceLevel = this.props.confidenceLevel;
    const kpis = this.props.kpis;
    const liftType = this.props.liftType;

    const comparisons = [];
    const plusInfinity = '+' + String.fromCharCode(8734);
    const minusInfinity = String.fromCharCode(8722) + String.fromCharCode(8734);

    results.forEach((c) => {
      const baseline = c.baseline;
      const variant = c.variant;
      const obsLift = c.obsLift;
      const pVal = c.pVal;
      const confIntLow = c.confIntLow;
      const confIntHigh = c.confIntHigh;
      const statSig = c.statSig;
      const W = c.W;

      let obsLiftF;
      if (obsLift === 'Infinity') {
        obsLiftF = plusInfinity;
      } else if (liftType === 'incremental') {
        obsLiftF = Number(obsLift).toLocaleString();
      } else {
        obsLiftF = formatFloat(obsLift);
      }

      let confIntLowF;
      if (confIntLow === '-Infinity') {
        confIntLowF = minusInfinity;
      } else if (liftType === 'incremental') {
        confIntLowF = Number(confIntLow).toLocaleString();
      } else {
        confIntLowF = formatFloat(confIntLow);
      }

      let confIntHighF;
      if (confIntHigh === 'Infinity') {
        confIntHighF = plusInfinity;
      } else if (liftType === 'incremental') {
        confIntHighF = Number(confIntHigh).toLocaleString();
      } else {
        confIntHighF = formatFloat(confIntHigh);
      }

      let pValF;
      if (pVal >= 0.01) {
        pValF = Number(pVal).toFixed(3);
      } else {
        pValF = Number(pVal).toExponential(2);
      }

      const asteriskF = statSig ? '*' : '';

      const wF = Number(W).toFixed(1);

      comparisons.push(
        <tr>
          <td>{baseline}</td>
          <td>{variant}</td>
          <td align="right">{obsLiftF}</td>
          <td align="right">
            {pValF}
            {asteriskF}
          </td>
          <td align="right">{wF}</td>
          <td align="right">{confIntLowF}</td>
          <td align="right">{confIntHighF}</td>
        </tr>,
      );
    });

    const nc = results.length * kpis;
    let minimumFractionDigits = -2 - Math.floor(Math.log10(alpha));
    if (minimumFractionDigits < 0) {
      minimumFractionDigits = 0;
    }
    const confidenceLevelF = Number(confidenceLevel).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: minimumFractionDigits,
    });

    const level = alpha;
    let levelF;
    if (level === 0.05) {
      levelF = level.toFixed(2);
    } else if (level >= 0.01) {
      levelF = level.toFixed(3);
    } else {
      levelF = level.toFixed(minimumFractionDigits + 3);
    }

    const simultaneous = nc > 1 ? 'Simultaneous ' : '';

    return (
      <div style={{ width: 650 }}>
        <table border="1">
          <tr>
            <td>Baseline</td>
            <td>Variant</td>
            <td>Observed Lift ({liftType})</td>
            <td>p-Value*</td>
            <td>W**</td>
            <td>Conf. Low***</td>
            <td>Conf. High***</td>
          </tr>
          {comparisons}
        </table>
        <span>
          * An asterisk in the p-value column indicates statistical significance at
          level {levelF}. The smaller the p-value (closer to zero), the stronger the
          evidence.
        </span>
        <br />
        <span>
          ** The W statistic is positive if the result is statistically significant. The
          higher W, the stronger the evidence.
        </span>
        <br />
        <span>
          ***{simultaneous}
          {confidenceLevelF} confidence interval
        </span>
        <br />
      </div>
    );
  }
}

AnalysisResults.propTypes = {
  results: PropTypes.array,
  alpha: PropTypes.number,
  confidenceLevel: PropTypes.number,
  kpis: PropTypes.number,
  liftType: PropTypes.string,
};

/**

 */
class AnalysisError extends React.Component {
  /**
   * Display an error message
   *
   * @return {JSX} The error message
   */
  render() {
    const status = this.props.status;
    const statusText = this.props.statusText;
    const errMsg = this.props.errMsg;

    if (status !== null && statusText !== null) {
      return (
        <div>
          <span>
            {status}: {statusText}
          </span>
          <br />
          <span>{errMsg}</span>
        </div>
      );
    } else {
      return (
        <div>
          <span>{errMsg}</span>
        </div>
      );
    }
  }
}

AnalysisError.propTypes = {
  status: PropTypes.string,
  statusText: PropTypes.string,
  errMsg: PropTypes.string,
};

/**

 */
class ContingencyTable extends React.Component {
  /**
   * Constructor for contingency table
   *
   * @param {object} props - properties to pass to parent object
   */
  constructor(props) {
    super(props);

    // Contingency Table
    this.onNumberChange = this.onNumberChange.bind(this);
    this.onNumberBlur = this.onNumberBlur.bind(this);
    this.completeTable = this.completeTable.bind(this);
    this.updateTotals = this.updateTotals.bind(this);
    this.onGroupNameChange = this.onGroupNameChange.bind(this);
    this.deleteRow = this.deleteRow.bind(this);

    // Advanced Settings
    this.displayAdvancedSettings = this.displayAdvancedSettings.bind(this);
    this.renderAdvancedSettings = this.renderAdvancedSettings.bind(this);
    this.onAdvancedSettingChange = this.onAdvancedSettingChange.bind(this);
    this.onAdvancedSettingComplete = this.onAdvancedSettingComplete.bind(this);

    // Buttons
    this.calculate = this.calculate.bind(this);
    this.addExperimentGroup = this.addExperimentGroup.bind(this);

    this.state = {
      rows: [
        {
          groupName: 'A',
          successes: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
          failures: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
          trials: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
        },
        {
          groupName: 'B',
          successes: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
          failures: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
          trials: {
            type: 'int',
            value: '',
            displayed: '',
            minValue: 0,
            maxValue: 1e14,
          },
        },
      ],
      totals: {
        successes: '',
        failures: '',
        trials: '',
      },
      tableComplete: false,
      displayAdvancedSettings: false,
      kpis: {
        type: 'int',
        value: 1,
        displayed: 1,
        minValue: 1,
        maxValue: 1000,
      },
      alpha: {
        type: 'float',
        value: 0.05,
        displayed: 0.05,
        minValue: 0.0,
        maxValue: 1.0,
      },
      nullLift: {
        type: 'float',
        value: 0,
        displayed: 0,
        minValue: -100,
        maxValue: 1000,
      },
      liftType: {
        type: 'string',
        value: 'relative',
      },
      error: {
        status: null,
        statusText: null,
        errMsg: null,
      },
      results: null,
    };
  }

  /**
   * Instantiate ExperimentGroup
   *
   * @param {integer} idx - The row index, starting from 0
   * @param {object} rowValues - The elements of the row
   * @param {bool} deletable - Specifies whether to include a delete button
   * @return {JSX} the row
   */
  renderExperimentGroup(idx, rowValues, deletable) {
    return (
      <ExperimentGroup
        idx={idx}
        groupName={rowValues.groupName}
        successes={rowValues.successes.displayed}
        failures={rowValues.failures.displayed}
        trials={rowValues.trials.displayed}
        deletable={deletable}
        deleteRow={this.deleteRow}
        onNumberChange={this.onNumberChange}
        onNumberBlur={this.onNumberBlur}
        completeTable={this.completeTable}
        onGroupNameChange={this.onGroupNameChange}
      />
    );
  }

  /**
   * Instantiate row of totals.
   *
   * @param {object} totals - The elements of the row
   * @return {JSX} the row
   */
  renderTotalValues(totals) {
    return (
      <TotalValues
        totalSuccesses={totals.successes}
        totalFailures={totals.failures}
        totalTrials={totals.trials}
      />
    );
  }

  /**
   * Handler for toggling display setting.
   *
   * @param {object} e - the onClick event
   */
  displayAdvancedSettings(e) {
    if (!e.target.checked) {
      this.setState({
        alpha: {
          type: 'float',
          value: 0.05,
          displayed: 0.05,
          minValue: 0.0,
          maxValue: 1.0,
        },
        nullLift: {
          type: 'float',
          value: 0,
          displayed: 0,
          minValue: -100,
          maxValue: 1000,
        },
        liftType: {
          type: 'string',
          value: 'relative',
        },
      });
    }
    this.setState({ displayAdvancedSettings: e.target.checked });
  }

  /**
   * Display the advanced settings.
   *
   * @return {JSX} the advanced settings, or blank
   */
  renderAdvancedSettings() {
    if (this.state.displayAdvancedSettings) {
      return (
        <AdvancedSettings
          alpha={this.state.alpha.displayed}
          nullLift={this.state.nullLift.displayed}
          liftType={this.state.liftType.value}
          onAdvancedSettingChange={this.onAdvancedSettingChange}
          onAdvancedSettingComplete={this.onAdvancedSettingComplete}
        />
      );
    } else {
      return '';
    }
  }

  /**
   * Handler for changing an advanced setting.
   *
   * @param {string} field - The field to update
   * @param {number} value - The entered value
   */
  onAdvancedSettingChange(field, value) {
    if (field === 'alpha') {
      const alpha = this.state.alpha;
      alpha.displayed = value;
      this.setState({ alpha: alpha });
    } else if (field === 'kpis') {
      const kpis = this.state.kpis;
      kpis.displayed = value;
      this.setState({ kpis: kpis });
    } else if (field === 'nullLift') {
      const nullLift = this.state.nullLift;
      nullLift.displayed = value;
      this.setState({ nullLift: nullLift });
    } else if (field === 'liftType') {
      const liftType = this.state.liftType;
      liftType.value = value;
      this.setState({ liftType: liftType });
    }
  }

  /**
   * Handler for changing an advanced setting.
   *
   * @param {string} field - The field to update
   * @param {number} value - The entered value
   */
  onAdvancedSettingComplete(field, value) {
    let cur;
    if (field === 'alpha') {
      cur = this.state.alpha;
    } else if (field === 'kpis') {
      cur = this.state.kpis;
    } else if (field === 'nullLift') {
      cur = this.state.nullLift;
    }

    const prevValue = cur.value;
    const type = cur.type;
    let validatedInput;
    if (type === 'float') {
      const input = parseFloat(value);
      if (
        value === '' ||
        Number.isNaN(input) ||
        input <= cur.minValue ||
        input >= cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    } else if (type === 'int') {
      const input = parseInt(value);
      if (
        value === '' ||
        Number.isNaN(input) ||
        input < cur.minValue ||
        input > cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    }

    cur.value = validatedInput;
    cur.displayed = validatedInput;

    if (field === 'alpha') {
      this.setState({ alpha: cur });
    } else if (field === 'kpis') {
      this.setState({ kpis: cur });
    } else if (field === 'nullLift') {
      this.setState({ nullLift: cur });
    }
  }

  /**
   * Handler for changing the group name.
   *
   * @param {integer} idx - The row number
   * @param {number} value - The entered value
   */
  onGroupNameChange(idx, value) {
    const { rows } = this.state;
    rows[idx].groupName = value;
    this.setState({ rows: rows });
  }

  /**
   * Handler for deleting a row of the contingency table.
   *
   * @param {integer} idx - The row number
   */
  deleteRow(idx) {
    const rows = this.state.rows;
    if (idx > 1) {
      rows.splice(idx, 1);
      this.setState({ rows: rows });
    }
  }

  /**
   * Handler for entering a number.
   *
   * @param {integer} idx - The row number
   * @param {string} field - The field changed
   * @param {string} value - The entered value
   */
  onNumberChange(idx, field, value) {
    const { rows } = this.state;
    if (field === 'successes') {
      rows[idx].successes.displayed = value;
    } else if (field === 'failures') {
      rows[idx].failures.displayed = value;
    } else if (field === 'trials') {
      rows[idx].trials.displayed = value;
    }

    this.setState({ rows: rows });
  }

  /**
   * Handler for entering a number.
   *
   * @param {integer} idx - The row number
   * @param {string} field - The field changed
   * @param {string} value - The entered value
   */
  onNumberBlur(idx, field, value) {
    const { rows } = this.state;
    let cur;
    if (field === 'successes') {
      cur = rows[idx].successes;
    } else if (field === 'failures') {
      cur = rows[idx].failures;
    } else if (field === 'trials') {
      cur = rows[idx].trials;
    }

    const prevValue = cur.value;
    let validatedInput;

    let input;
    if (typeof value === 'number') {
      input = value;
    } else {
      input = parseInt(value.replace(/,/g, ''));
    }

    if (value === '') {
      validatedInput = value;
    } else if (Number.isNaN(input) || input < cur.minValue || input > cur.maxValue) {
      validatedInput = prevValue;
    } else {
      validatedInput = input.toString();
    }

    if (field === 'successes') {
      rows[idx].successes.value = validatedInput;
      rows[idx].successes.displayed = validatedInput;
    } else if (field === 'failures') {
      rows[idx].failures.value = validatedInput;
      rows[idx].failures.displayed = validatedInput;
    } else if (field === 'trials') {
      rows[idx].trials.value = validatedInput;
      rows[idx].trials.displayed = validatedInput;
    }

    this.setState({ rows: rows });
  }

  /**
   * Autocomplete the missing value.
   *
   * @param {integer} idx - The row number
   */
  completeTable(idx) {
    const successes = this.state.rows[idx].successes.value;
    const failures = this.state.rows[idx].failures.value;
    const trials = this.state.rows[idx].trials.value;

    if (successes === '' && failures !== '' && trials !== '') {
      this.onNumberBlur(idx, 'successes', trials - failures);
    } else if (successes !== '' && failures === '' && trials !== '') {
      this.onNumberBlur(idx, 'failures', trials - successes);
    } else if (successes !== '' && failures !== '' && trials === '') {
      this.onNumberBlur(idx, 'trials', successes + failures);
    }

    this.updateTotals();
  }

  /**
   * Update the totals row when all entries for a column complete.
   */
  updateTotals() {
    let successes = 0;
    let failures = 0;
    let trials = 0;

    let anyNullSuccesses = false;
    let anyNullFailures = false;
    let anyNullTrials = false;

    const { rows } = this.state;
    rows.forEach((row) => {
      if (row.successes.value === '') {
        anyNullSuccesses = true;
      } else {
        successes += parseInt(row.successes.value.replace(/,/g, ''));
      }

      if (row.failures.value === '') {
        anyNullFailures = true;
      } else {
        failures += parseInt(row.failures.value.replace(/,/g, ''));
      }

      if (row.trials.value === '') {
        anyNullTrials = true;
      } else {
        trials += parseInt(row.trials.value.replace(/,/g, ''));
      }
    });

    if (anyNullSuccesses) {
      successes = '';
    }

    if (anyNullFailures) {
      failures = '';
    }

    if (anyNullTrials) {
      trials = '';
    }

    if (anyNullSuccesses || anyNullFailures || anyNullTrials) {
      this.setState({ tableComplete: false });
    } else {
      this.setState({ tableComplete: true });
    }

    const totals = {
      successes: successes.toString(),
      failures: failures.toString(),
      trials: trials.toString(),
    };

    this.setState({ totals: totals });
  }

  /**
   * Add a row to the contingency table.
   */
  addExperimentGroup() {
    const groupNames = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const rows = this.state.rows;
    const ng = rows.length;
    const groupName = groupNames.charAt(ng);
    const newRow = {
      groupName: groupName,
      successes: {
        type: 'int',
        value: '',
        displayed: '',
        minValue: 0,
        maxValue: 1e14,
      },
      failures: {
        type: 'int',
        value: '',
        displayed: '',
        minValue: 0,
        maxValue: 1e14,
      },
      trials: {
        type: 'int',
        value: '',
        displayed: '',
        minValue: 0,
        maxValue: 1e14,
      },
    };
    rows.push(newRow);
    this.setState({ rows: rows });
  }

  /**
   * Make a request to the backend and handle the result.
   */
  calculate() {
    const tableComplete = this.state.tableComplete;
    if (tableComplete) {
      const data = {
        names: [],
        trials: [],
        successes: [],
        alpha: this.state.alpha.value,
        kpis: this.state.kpis.value,
        nullLift: this.state.nullLift.value / 100.0,
        liftType: this.state.liftType.value,
      };

      this.state.rows.forEach((row) => {
        data.names.push(row.groupName);
        data.trials.push(parseInt(row.trials.value));
        data.successes.push(parseInt(row.successes.value));
      });

      axios
        .post('/api/analysis', data)
        .then((resp) => {
          const results = [];
          resp.data.forEach((c) => {
            const result = {
              baseline: c.baseline,
              variant: c.variant,
              alpha: c.alpha,
              confidenceLevel: 1 - this.state.alpha.value,
              kpis: c.kpis,
              obsLift: c.observedLift,
              pVal: c.pValue,
              statSig: c.statSig,
              W: c.W,
              confIntLow: c.confIntLow,
              confIntHigh: c.confIntHigh,
            };
            results.push(result);
          });
          this.setState({
            error: {
              status: null,
              statusText: null,
              errMsg: null,
            },
            results: results,
            cachedLiftType: data['liftType'],
          });
        })
        .catch((error) => {
          const status = error.response.status;
          const statusText = error.response.statusText;
          const errMsg = error.response.data;
          this.setState({
            error: {
              status: status,
              statusText: statusText,
              errMsg: errMsg,
            },
          });
        });
    } else {
      this.setState({
        error: {
          status: null,
          statusText: null,
          errMsg: 'Please complete table',
        },
      });
    }
  }

  /**
   * Display the results of the statistics calculations.
   *
   * @return {JSX} the results
   */
  displayResults() {
    const error = this.state.error;
    const results = this.state.results;
    if (error.errMsg !== null) {
      return (
        <AnalysisError
          status={error.status}
          statusText={error.statusText}
          errMsg={error.errMsg}
        />
      );
    } else if (results === null) {
      return '';
    } else {
      const alpha = results[0].alpha;
      const confidenceLevel = results[0].confidenceLevel;
      const kpis = results[0].kpis;
      const liftType = this.state.cachedLiftType;
      return (
        <AnalysisResults
          results={results}
          alpha={alpha}
          confidenceLevel={confidenceLevel}
          kpis={kpis}
          liftType={liftType}
        />
      );
    }
  }

  /**
   * Render the contingency table.
   *
   * @return {JSX} The contingency table
   */
  render() {
    const rows = this.state.rows;
    const contingencyRows = [];
    for (let i = 0; i < rows.length; i++) {
      const deletable = i > 1;
      contingencyRows.push(this.renderExperimentGroup(i, rows[i], deletable));
    }
    const totals = this.state.totals;
    const kpis = this.state.kpis.displayed;
    return (
      <ErrorBoundary>
        <div>
          <table>
            <tbody>
              <tr>
                <td align="center">Group Name</td>
                <td align="center">Successes</td>
                <td align="center">Failures</td>
                <td align="center">Trials</td>
                <td align="center">Success Rate</td>
                <td></td>
              </tr>
              {contingencyRows}
              {this.renderTotalValues(totals)}
              <tr>
                <td>Num. KPIs</td>
                <td>
                  <input
                    style={{
                      width: 60,
                      textAlign: 'right',
                    }}
                    type="text"
                    value={kpis}
                    onChange={(e) => {
                      this.onAdvancedSettingChange('kpis', e.target.value);
                    }}
                    onBlur={(e) => {
                      this.onAdvancedSettingComplete('kpis', e.target.value);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <input type="checkbox" onClick={this.displayAdvancedSettings.bind(this)} />
          Enable Advanced Settings
          <br />
          {this.renderAdvancedSettings()}
          <input type="button" value="Calculate" onClick={this.calculate} />
          <input type="button" value="Add row" onClick={this.addExperimentGroup} />
          <div>{this.displayResults()}</div>
          <AnalysisFAQ />
        </div>
      </ErrorBoundary>
    );
  }
}

/**

*/
class ErrorBoundary extends React.Component {
  /**
   * Constructor
   * @param {obj} props - properties
   */
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  /**
   * Set state on error
   * @param {obj} error - Error object
   *
   * @return {x} New state
   */
  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  /**
   * Render boundary
   *
   * @return {x} Entity
   */
  render() {
    if (this.state.hasError) {
      return <h1>Something went wrong.</h1>;
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.object,
};

export default ContingencyTable;
