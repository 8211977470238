import React from 'react';
import PropTypes from 'prop-types';
import PlanningFAQ from './planningFAQ.jsx';
const axios = require('axios');

/**

 */
class PlanningDropDownModal extends React.Component {
  /**
   * Render the drop down to select planning type
   *
   * @return {JSX} The drop down modal.
   */
  render() {
    const selected = this.props.selected;
    return (
      <select
        defaultValue={selected}
        onChange={(e) => {
          this.props.onChange(e.target.value);
        }}
      >
        <option value="sampleSize">Sample Size</option>
        <option value="sensitivity">Sensitivity</option>
        <option value="power">Power</option>
      </select>
    );
  }
}

PlanningDropDownModal.propTypes = {
  selected: PropTypes.string,
  onChange: PropTypes.func,
};

/**

 */
class BaselineRateModal extends React.Component {
  /**
   * Render the baseline success rate modal.
   *
   * @return {JSX} The baseline success rate modal.
   */
  render() {
    const baseline = this.props.baseline;
    const hoverText =
      'Anticipated success rate in control group, usually based on history.';
    return (
      <tr>
        <td>
          <span title={hoverText}>Baseline Success Rate</span>
        </td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={baseline}
            onChange={(e) => {
              this.props.onFieldChange('baseline', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('baseline', e.target.value);
            }}
          />
          &#37;
        </td>
      </tr>
    );
  }
}

BaselineRateModal.propTypes = {
  baseline: PropTypes.string,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
};

/**

 */
class DesiredSensitivityModal extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const sensitivity = this.props.sensitivity;
    const nullLift = this.props.nullLift;
    let desc;
    if (nullLift === 0.0) {
      desc = (
        <span title="Minimum detectable lift relative to baseline">
          Desired Sensitivity
        </span>
      );
    } else {
      desc = (
        <span title="Minimum detectable lift relative to baseline">
          Lift under Alternative Hypothesis
        </span>
      );
    }

    return (
      <tr>
        <td>{desc}</td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={sensitivity}
            onChange={(e) => {
              this.props.onFieldChange('sensitivity', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('sensitivity', e.target.value);
            }}
          />
          &#37;
        </td>
      </tr>
    );
  }
}

DesiredSensitivityModal.propTypes = {
  sensitivity: PropTypes.string,
  nullLift: PropTypes.number,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
};

/**

 */
class SampleSizeModal extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const sampleSize = this.props.sampleSize;
    return (
      <tr>
        <td>
          <span title="Total sample size across all groups">Sample Size</span>
        </td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={sampleSize}
            onChange={(e) => {
              this.props.onFieldChange('sampleSize', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('sampleSize', e.target.value);
            }}
          />
        </td>
      </tr>
    );
  }
}

SampleSizeModal.propTypes = {
  sampleSize: PropTypes.number,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
};

/**

 */
class ExperimentGroupsModal extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const experimentGroups = this.props.experimentGroups;
    return (
      <tr>
        <td>Experiment Groups</td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={experimentGroups}
            onChange={(e) => {
              this.props.onFieldChange('experimentGroups', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('experimentGroups', e.target.value);
            }}
          />
        </td>
      </tr>
    );
  }
}

ExperimentGroupsModal.propTypes = {
  experimentGroups: PropTypes.number,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
};

/**

 */
class TrafficModal extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const traffic = this.props.traffic;
    const disabled = this.props.enabled ? '' : 'disabled';
    return (
      <tr>
        <td>% Traffic in Baseline</td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={traffic}
            onChange={(e) => {
              this.props.onFieldChange('traffic', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('traffic', e.target.value);
            }}
            disabled={disabled}
          />
          &#37;
        </td>
      </tr>
    );
  }
}

TrafficModal.propTypes = {
  traffic: PropTypes.number,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
  enabled: PropTypes.bool,
};

/**

 */
class KPIsModal extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const kpis = this.props.kpis;
    return (
      <tr>
        <td>
          <span title="Number of success metrics">KPIs</span>
        </td>
        <td>
          <input
            className="defaultTextbox"
            type="text"
            value={kpis}
            onChange={(e) => {
              this.props.onFieldChange('kpis', e.target.value);
            }}
            onBlur={(e) => {
              this.props.onNumberBlur('kpis', e.target.value);
            }}
          />
        </td>
      </tr>
    );
  }
}

KPIsModal.propTypes = {
  kpis: PropTypes.number,
  onFieldChange: PropTypes.func,
  onNumberBlur: PropTypes.func,
};

/**

 */
class PlanningAdvancedSettings extends React.Component {
  /**
   * Render advanced settings.
   *
   * @return {JSX} The advanced settings
   */
  render() {
    const alpha = this.props.alpha;
    const beta = this.props.beta;
    const nullLift = this.props.nullLift;
    const liftType = this.props.liftType;
    const permitIncrSucc = this.props.permitIncrSucc;
    return (
      <table>
        <tr>
          <td>Type-I Error Threshold (&alpha;)</td>
          <td>
            <input
              className="defaultTextbox"
              type="text"
              value={alpha}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('alpha', e.target.value);
              }}
              onBlur={(e) => {
                this.props.onAdvancedSettingComplete('alpha', e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Type-II Error Threshold (&beta;)</td>
          <td>
            <input
              className="defaultTextbox"
              type="text"
              value={beta}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('beta', e.target.value);
              }}
              onBlur={(e) => {
                this.props.onAdvancedSettingComplete('beta', e.target.value);
              }}
            />
          </td>
        </tr>
        <tr>
          <td>Lift Under Null Hypothesis</td>
          <td>
            <input
              className="defaultTextbox"
              type="text"
              value={nullLift}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('nullLift', e.target.value);
              }}
              onBlur={(e) => {
                this.props.onAdvancedSettingComplete('nullLift', e.target.value);
              }}
            />
            &#37;
          </td>
        </tr>
        <tr>
          <td>Lift Type</td>
          <td>
            <select
              defaultValue={liftType}
              onChange={(e) => {
                this.props.onAdvancedSettingChange('liftType', e.target.value);
              }}
            >
              <option value="relative">Relative</option>
              <option value="absolute">Absolute %</option>
              {permitIncrSucc ? (
                <>
                  <option value="incrementalBaseline">Incr. Succ. (Baseline)</option>
                  <option value="incrementalVariant">Incr. Succ. (Variant)</option>
                </>
              ) : (
                ''
              )}
            </select>
          </td>
        </tr>
      </table>
    );
  }
}

PlanningAdvancedSettings.propTypes = {
  alpha: PropTypes.number,
  beta: PropTypes.number,
  nullLift: PropTypes.number,
  liftType: PropTypes.string,
  permitIncrSucc: PropTypes.bool,
  onAdvancedSettingChange: PropTypes.func,
  onAdvancedSettingComplete: PropTypes.func,
};

/**

 */
class PlanningCalculator extends React.Component {
  /**
   * Constructor for planning calulator
   *
   * @param {object} props - properties to pass to parent object
   */
  constructor(props) {
    super(props);

    this.onPlanningTypeChange = this.onPlanningTypeChange.bind(this);
    this.onFieldChange = this.onFieldChange.bind(this);
    this.onNumberBlur = this.onNumberBlur.bind(this);

    // Advanced settings
    this.displayAdvancedSettings = this.displayAdvancedSettings.bind(this);
    this.renderAdvancedSettings = this.renderAdvancedSettings.bind(this);
    this.onAdvancedSettingChange = this.onAdvancedSettingChange.bind(this);
    this.onAdvancedSettingComplete = this.onAdvancedSettingComplete.bind(this);

    this.calculate = this.calculate.bind(this);

    this.state = {
      valueToBeCalculated: 'sampleSize',
      baselineSuccessRate: {
        type: 'float',
        value: '',
        displayed: '',
        minValue: 0.0,
        maxValue: 100.0,
      },
      sensitivity: {
        type: 'float',
        value: '',
        displayed: '',
        minValue: 0.0,
        maxValue: 100.0,
      },
      sampleSize: {
        type: 'int',
        value: '',
        displayed: '',
        minValue: 1,
        maxValue: 1e15,
      },
      experimentGroups: {
        type: 'int',
        value: 2,
        displayed: 2,
        minValue: 2,
        maxValue: 1000,
      },
      traffic: {
        type: 'float',
        value: 50.0,
        displayed: 50.0,
        minValue: 0.0,
        maxValue: 100.0,
      },
      kpis: {
        type: 'int',
        value: 1,
        displayed: 1,
        minValue: 1,
        maxValue: 1000,
      },
      displayAdvancedSettings: false,
      alpha: {
        type: 'float',
        value: 0.05,
        displayed: 0.05,
        minValue: 0.0,
        maxValue: 1.0,
      },
      beta: {
        type: 'float',
        value: 0.2,
        displayed: 0.2,
        minValue: 0.0,
        maxValue: 1.0,
      },
      nullLift: {
        type: 'float',
        value: 0,
        displayed: 0,
        minValue: -100,
        maxValue: 1000,
      },
      liftType: {
        type: 'string',
        value: 'relative',
      },
      error: {
        status: null,
        statusText: null,
        errMsg: null,
      },
      results: null,
    };
  }

  /**
   * Make a request to the backend and handle the result.
   */
  calculate() {
    let tableComplete;
    const valueToBeCalculated = this.state.valueToBeCalculated;
    const baselineSuccessRate = this.state.baselineSuccessRate.value;
    const sensitivity = this.state.sensitivity.value;
    const sampleSize = this.state.sampleSize.value;
    if (valueToBeCalculated === 'sampleSize') {
      tableComplete = baselineSuccessRate !== '' && sensitivity !== '';
    } else if (valueToBeCalculated === 'sensitivity') {
      tableComplete = baselineSuccessRate !== '' && sampleSize !== '';
    } else if (valueToBeCalculated === 'power') {
      tableComplete =
        baselineSuccessRate !== '' && sensitivity !== '' && sampleSize !== '';
    }

    if (tableComplete) {
      const data = {
        baseline: baselineSuccessRate / 100.0,
        alpha: this.state.alpha.value,
        beta: this.state.beta.value,
        liftType: this.state.liftType.value,
        experimentGroups: this.state.experimentGroups.value,
        kpis: this.state.kpis.value,
        nullLift: this.state.nullLift.value / 100.0,
      };

      if (data['experimentGroups'] === 2) {
        data['groupProportions'] = [
          this.state.traffic.value / 100.0,
          1.0 - this.state.traffic.value / 100.0,
        ];
      }

      if (valueToBeCalculated === 'sampleSize' || valueToBeCalculated === 'power') {
        data.altLift = sensitivity / 100.0;
      }

      if (valueToBeCalculated === 'sensitivity' || valueToBeCalculated === 'power') {
        data.sampleSize = sampleSize;
      }

      axios
        .post('/api/planning', data)
        .then((resp) => {
          this.setState({
            error: {
              status: null,
              statusText: null,
              errMsg: null,
            },
            results: resp.data,
          });
        })
        .catch((error) => {
          const status = error.response.status;
          const statusText = error.response.statusText;
          const errMsg = error.response.data;
          this.setState({
            error: {
              status: status,
              statusText: statusText,
              errMsg: errMsg,
            },
          });
        });
    } else {
      this.setState({
        error: {
          status: null,
          statusText: null,
          errMsg: 'Please supply required inputs',
        },
      });
    }
  }

  /**
   * Display the results of the statistics calculations.
   *
   * @return {JSX} the results
   */
  displayResults() {
    const error = this.state.error;
    const results = this.state.results;
    if (error.errMsg !== null) {
      const status = error.status;
      const statusText = error.statusText;
      const errMsg = error.errMsg;
      if (status !== null && statusText !== null) {
        return (
          <div>
            <span>
              {status}: {statusText}
            </span>
            <br />
            <span>{errMsg}</span>
          </div>
        );
      } else {
        return (
          <div>
            <span>{errMsg}</span>
          </div>
        );
      }
    } else if (results === null) {
      return '';
    } else {
      if ('sampleSize' in results) {
        const sampleSize = results.sampleSize
          .toString()
          .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
        return <span>Required Sample Size (across all groups): {sampleSize}</span>;
      } else if ('minimumDetectableLift' in results) {
        let mdl;
        let mdd;
        if (
          this.state.liftType.value === 'incrementalBaseline' ||
          this.state.liftType.value === 'incrementalVariant'
        ) {
          mdl = Math.round(results.minimumDetectableLift).toString();
          mdd = Math.round(results.minimumDetectableDrop).toString();
        } else {
          mdl = this.formatFloat(results.minimumDetectableLift);
          mdd = this.formatFloat(results.minimumDetectableDrop);
        }

        return (
          <span>
            Minimum Detectable Lift: {mdl}
            <br />
            Minimum Detectable Drop: {mdd}
          </span>
        );
      } else if ('power' in results) {
        const power = Number(results.power).toLocaleString(undefined, {
          style: 'percent',
          minimumFractionDigits: 1,
        });
        return <span>Power: {power}</span>;
      } else {
        return <span>Results not recognized</span>;
      }
    }
  }

  /**
   * Handler for changing planning type.
   *
   * @param {number} value - The entered value
   */
  onPlanningTypeChange(value) {
    if (this.state.valueToBeCalculated === 'sensitivity' && value !== 'sensitivity') {
      const liftType = this.state.liftType;
      liftType.value = 'relative';
      this.setState({ liftType: liftType });
    }
    this.setState({ valueToBeCalculated: value });
  }

  /**
   * Handler for entering input in a field.
   *
   * @param {string} field - The field changed
   * @param {number} value - The entered value
   */
  onFieldChange(field, value) {
    if (field === 'baseline') {
      const baselineSuccessRate = this.state.baselineSuccessRate;
      baselineSuccessRate.displayed = value;
      this.setState({ baselineSuccessRate: baselineSuccessRate });
    } else if (field === 'sensitivity') {
      const sensitivity = this.state.sensitivity;
      sensitivity.displayed = value;
      this.setState({ sensitivity: sensitivity });
    } else if (field === 'sampleSize') {
      const sampleSize = this.state.sampleSize;
      sampleSize.displayed = value;
      this.setState({ sampleSize: sampleSize });
    } else if (field === 'experimentGroups') {
      const experimentGroups = this.state.experimentGroups;
      experimentGroups.displayed = value;
      this.setState({ experimentGroups: experimentGroups });
    } else if (field === 'traffic') {
      const traffic = this.state.traffic;
      traffic.displayed = value;
      this.setState({ traffic: traffic });
    } else if (field === 'kpis') {
      const kpis = this.state.kpis;
      kpis.displayed = value;
      this.setState({ kpis: kpis });
    }
  }

  /**
   * Handler for validating numeric input
   *
   * @param {string} field - The field changed
   * @param {number} value - The entered value
   */
  onNumberBlur(field, value) {
    let cur;
    if (field === 'baseline') {
      cur = this.state.baselineSuccessRate;
    } else if (field === 'sensitivity') {
      cur = this.state.sensitivity;
    } else if (field === 'sampleSize') {
      cur = this.state.sampleSize;
    } else if (field === 'experimentGroups') {
      cur = this.state.experimentGroups;
    } else if (field === 'traffic') {
      cur = this.state.traffic;
    } else if (field === 'kpis') {
      cur = this.state.kpis;
    }

    const prevValue = cur.value;
    const type = cur.type;
    let validatedInput;
    if (type === 'float') {
      const input = parseFloat(value);
      if (
        value === '' ||
        Number.isNaN(input) ||
        input <= cur.minValue ||
        input >= cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    } else if (type === 'int') {
      const input = parseInt(value.replace(/,/g, ''));
      if (
        value === '' ||
        Number.isNaN(input) ||
        input < cur.minValue ||
        input > cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    }

    cur.value = validatedInput;
    cur.displayed = validatedInput;

    if (field === 'baseline') {
      this.setState({ baselineSuccessRate: cur });
    } else if (field === 'sensitivity') {
      this.setState({ sensitivity: cur });
    } else if (field === 'sampleSize') {
      this.setState({ sampleSize: cur });
    } else if (field === 'experimentGroups') {
      this.setState({ experimentGroups: cur });
      const curTraffic = this.state.traffic;
      curTraffic.value = 100.0 / cur.value;
      curTraffic.displayed = 100.0 / cur.value;
      this.setState({ traffic: curTraffic });
    } else if (field === 'traffic') {
      this.setState({ traffic: cur });
    } else if (field === 'kpis') {
      this.setState({ kpis: cur });
    }
  }

  /**
   * Handler for toggling display setting.
   *
   * @param {object} e - the onClick event
   */
  displayAdvancedSettings(e) {
    if (!e.target.checked) {
      this.setState({
        alpha: {
          type: 'float',
          value: 0.05,
          displayed: 0.05,
          minValue: 0.0,
          maxValue: 1.0,
        },
        beta: {
          type: 'float',
          value: 0.2,
          displayed: 0.2,
          minValue: 0.0,
          maxValue: 1.0,
        },
        nullLift: {
          type: 'float',
          value: 0,
          displayed: 0,
          minValue: -100,
          maxValue: 1000,
        },
        liftType: {
          type: 'str',
          value: 'relative',
        },
      });
    }
    this.setState({ displayAdvancedSettings: e.target.checked });
  }

  /**
   * Display the advanced settings.
   *
   * @return {JSX} the advanced settings, or blank
   */
  renderAdvancedSettings() {
    if (this.state.displayAdvancedSettings) {
      return (
        <PlanningAdvancedSettings
          alpha={this.state.alpha.displayed}
          beta={this.state.beta.displayed}
          nullLift={this.state.nullLift.displayed}
          liftType={this.state.liftType.value}
          permitIncrSucc={this.state.valueToBeCalculated === 'sensitivity'}
          onAdvancedSettingChange={this.onAdvancedSettingChange}
          onAdvancedSettingComplete={this.onAdvancedSettingComplete}
        />
      );
    } else {
      return '';
    }
  }

  /**
   * Handler for changing an advanced setting.
   *
   * @param {string} field - The field to update
   * @param {number} value - The entered value
   */
  onAdvancedSettingChange(field, value) {
    if (field === 'alpha') {
      const alpha = this.state.alpha;
      alpha.displayed = value;
      this.setState({ alpha: alpha });
    } else if (field === 'beta') {
      const beta = this.state.beta;
      beta.displayed = value;
      this.setState({ beta: beta });
    } else if (field === 'nullLift') {
      const nullLift = this.state.nullLift;
      nullLift.displayed = value;
      this.setState({ nullLift: nullLift });
    } else if (field === 'liftType') {
      const liftType = this.state.liftType;
      liftType.value = value;
      this.setState({ liftType: liftType });
    }
  }

  /**
   * Handler for changing an advanced setting.
   *
   * @param {string} field - The field to update
   * @param {number} value - The entered value
   */
  onAdvancedSettingComplete(field, value) {
    let cur;
    if (field === 'alpha') {
      cur = this.state.alpha;
    } else if (field === 'beta') {
      cur = this.state.beta;
    } else if (field === 'experimentGroups') {
      cur = this.state.experimentGroups;
    } else if (field === 'kpis') {
      cur = this.state.kpis;
    } else if (field === 'nullLift') {
      cur = this.state.nullLift;
    }

    const prevValue = cur.value;
    const type = cur.type;
    let validatedInput;
    if (type === 'float') {
      const input = parseFloat(value);
      if (
        value === '' ||
        Number.isNaN(input) ||
        input <= cur.minValue ||
        input >= cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    } else if (type === 'int') {
      const input = parseInt(value);
      if (
        value === '' ||
        Number.isNaN(input) ||
        input < cur.minValue ||
        input > cur.maxValue
      ) {
        validatedInput = prevValue;
      } else {
        validatedInput = input;
      }
    }

    cur.value = validatedInput;
    cur.displayed = validatedInput;

    if (field === 'alpha') {
      this.setState({ alpha: cur });
    } else if (field === 'beta') {
      this.setState({ beta: cur });
    } else if (field === 'experimentGroups') {
      this.setState({ experimentGroups: cur });
    } else if (field === 'kpis') {
      this.setState({ kpis: cur });
    } else if (field === 'nullLift') {
      this.setState({ nullLift: cur });
    }
  }

  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    const valueToBeCalculated = this.state.valueToBeCalculated;

    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td>Calculate</td>
              <td>
                <PlanningDropDownModal
                  selected={valueToBeCalculated}
                  onChange={this.onPlanningTypeChange}
                />
              </td>
            </tr>
            <BaselineRateModal
              baseline={this.state.baselineSuccessRate.displayed}
              onFieldChange={this.onFieldChange}
              onNumberBlur={this.onNumberBlur}
            />
            {valueToBeCalculated === 'sampleSize' || valueToBeCalculated === 'power' ? (
              <DesiredSensitivityModal
                sensitivity={this.state.sensitivity.displayed}
                nullLift={this.state.nullLift.value}
                onFieldChange={this.onFieldChange}
                onNumberBlur={this.onNumberBlur}
              />
            ) : null}
            {valueToBeCalculated === 'sensitivity' ||
            valueToBeCalculated === 'power' ? (
              <SampleSizeModal
                sampleSize={this.state.sampleSize.displayed}
                onFieldChange={this.onFieldChange}
                onNumberBlur={this.onNumberBlur}
              />
            ) : null}
            <ExperimentGroupsModal
              experimentGroups={this.state.experimentGroups.displayed}
              onFieldChange={this.onFieldChange}
              onNumberBlur={this.onNumberBlur}
            />
            <TrafficModal
              traffic={this.state.traffic.displayed}
              onFieldChange={this.onFieldChange}
              onNumberBlur={this.onNumberBlur}
              enabled={this.state.experimentGroups.displayed === 2}
            />
            <KPIsModal
              kpis={this.state.kpis.displayed}
              onFieldChange={this.onFieldChange}
              onNumberBlur={this.onNumberBlur}
            />
          </tbody>
        </table>
        <input type="checkbox" onClick={this.displayAdvancedSettings.bind(this)} />
        Enable Advanced Settings
        <br />
        {this.renderAdvancedSettings()}
        <input type="button" value="Calculate" onClick={this.calculate} />
        <div>{this.displayResults()}</div>
        <PlanningFAQ />
      </div>
    );
  }

  /**
   * Format float
   *
   * @param {float} x - A number
   *
   * @return {x} A formatted string
   */
  formatFloat(x) {
    const minimumFractionDigits = Math.max(0, -Math.floor(Math.log10(Math.abs(x))));

    return Number(x).toLocaleString(undefined, {
      style: 'percent',
      minimumFractionDigits: minimumFractionDigits,
    });
  }
}

export default PlanningCalculator;
