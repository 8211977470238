import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import ContingencyTable from './contingencyTable.jsx';
import PlanningCalculator from './planningCalculator.jsx';

/**

 */
class ABTestingCalculator extends React.Component {
  /**
   * Constructor for A/B testing calulator
   *
   * @param {object} props - properties to pass to parent object
   */
  constructor(props) {
    super(props);

    this.state = {
      calculator: 'planning',
    };
  }

  /**
   * Render the A/B Testing Calculator
   *
   * @return {JSX} The A/B Testing Calculator.
   */
  render() {
    const calculator = this.state.calculator;
    let planning;
    let analysis;
    if (calculator === 'planning') {
      planning = 'Planning';
      analysis = (
        <a
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            this.setState({ calculator: 'analysis' });
            return false;
          }}
        >
          Analysis
        </a>
      );
    } else {
      analysis = 'Analysis';
      planning = (
        <a
          style={{
            color: 'blue',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          onClick={(e) => {
            this.setState({ calculator: 'planning' });
            return false;
          }}
        >
          Planning
        </a>
      );
    }
    return (
      <div>
        <div>
          <span>{planning}</span>&nbsp;|&nbsp;
          <span>{analysis}</span>
        </div>
        <div>
          {calculator === 'planning' ? <PlanningCalculator /> : <ContingencyTable />}
        </div>
      </div>
    );
  }
}

// ========================================
ReactDOM.render(<ABTestingCalculator />, document.getElementById('root'));
