import React from 'react';

/**

 */
class AnalysisFAQ extends React.Component {
  /**
   * Render the planning calculator
   *
   * @return {JSX} The planning calculator
   */
  render() {
    return (
      <div
        style={{
          width: 600,
        }}
      >
        <h2>FAQ</h2>
        <b>What is this?</b>
        <div>
          <p>
            This is a calculator for analyzing simple A/B tests (or A/B/C tests,
            or...you get the idea). If you want to plan a new test, click on the
            &lsquo;Planning&rsquo; link at the top. If your Key Performance Indicator
            (KPI) can be interpreted as a numerator-over-a-denominator, we can think of
            that KPI as a success rate. That includes email open rates, conversion
            rates, and even customer complaint rates, even though the latter might more
            accurately be described as a failure rate.
          </p>

          <p>
            Not all KPIs can be interpreted as a success rate, and this calculator
            cannot be used for analyzing such tests.
          </p>
        </div>
        <b>How do I use it?</b>
        <div>
          <p>
            Just enter the number of trials (the denominator of the KPI), the number of
            successes (the numerator of the KPI), and the failures will auto-update.
            Sometimes it is more natural to specify the number of successes and
            failures, so we can enter those directly, too.
          </p>

          <p>
            The totals will update as you complete the table, providing a helpful
            summary of the experiment. This type of table is called a{' '}
            <i>Contingency Table</i>. When the table is complete, hit the calculate
            button.
          </p>
        </div>
        <b>What if I have more than 2 experiment groups?</b>
        <div>
          <p>
            Just hit the &lsquo;Add row&rsquo; button. (If you know about the multiple
            comparisons problem, the calculator will automatically make the appropriate
            adjustments.)
          </p>
        </div>
        <b>How do I interpret the results?</b>
        <div>
          <p>
            The results consist of a row for each comparison, consisting of each pair of
            experiment groups. One group is designated as the baseline, the other as the
            variant, for the purposes of comparison, but this designation is arbitrary.
            You can see the observed lift of the variant relative to the baseline, which
            is the percent difference in success rates (e.g. 12% is 20% higher than
            10%). We also print a <i>p-value</i> and a <i>confidence interval</i> on the
            relative lift.
          </p>
        </div>
        <b>What is a p-value?</b>
        <div>
          <p>There is a technical definition and a more intuitive interpretation.</p>

          <p>
            The intuitive interpretation is as a way of quantifying the strength of
            evidence that the observed difference between the two groups is not entirely
            due to the random assignment of experimental units (people) to experiment
            groups. The lower the p-value, the stronger the evidence. Some A/B testing
            calculators will subtract the p-value from 1 and report a
            &lsquo;confidence&rsquo;, but I find this confusing when we talk about
            confidence intervals. (These other calculators tend not to report confidence
            intervals.)
          </p>

          <p>
            The technical definition is:{' '}
            <i>
              the probability, under the conditions of the null hypothesis, of an
              outcome at least as extreme as that actually observed.
            </i>{' '}
            Sometimes the p-value is described as the probability the null hypothesis is
            true, but this is gibberish&ndash;the null hypothesis is either true, or it
            isn&apos;t, but probability has nothing to do with it.
          </p>

          <p>
            However we think of it, when the p-value is less than some agreed-upon
            threshold, we say the result is &lsquo;statistically significant&rsquo;.
            This terminology is overused and even misapplied. I recommend avoiding it
            and always reporting the p-value itself, along with a confidence interval.
          </p>
        </div>
        <b>What is a confidence interval?</b>
        <div>
          <p>
            A confidence interval is a range of possibilities that are consistent with
            the data. Just as important as the values within the confidence interval are
            the values outside of the interval: we can effectively rule out these values
            as being inconsistent with the data.
          </p>
        </div>
        <b>What&apos;s that W statistic?</b>
        <div>
          <p>
            Many people find p-values to be counterintuitive: the smaller the p-value,
            the stronger the evidence. Typically, when the p-value is less than 0.05, we
            say the result is statistically significant. It&apos;s actually more
            complicated than that. The right interpretation depends on how many KPIs,
            how many experiment groups, among other concerns. This is all intended to be
            captured in W, an innovative statistic which is positive only if the result
            is statistically significant. Moreover, the larger W, the stronger the
            evidence. It is equal to the base-10 logarithm of the ratio of the Type-I
            error threshold to the p-value. The p-value is still the{' '}
            <i>lingua franca</i> of hypothesis testing; W is just another tool for
            quantifying the strength of evidence.
          </p>
        </div>
        <b>
          What if my KPI is not a success rate? What if my null hypothesis is more
          complicated? What if...?
        </b>
        <div>
          <p>
            There is no one-size-fits-all approach to experimentation. Designing and
            analyzing experiments often require a blend of creativity and advanced
            statistics. This is just a starting point.
          </p>
        </div>
        <b>Where can I read more about A/B testing?</b>
        <div>
          <ul>
            <li>
              <a href="https://www.adventuresinwhy.com/post/ab-testing-random-sampling/">
                Adventures in Why
              </a>
              &nbsp;(my blog)
            </li>
            <li>
              <a href="https://www.evanmiller.org/how-not-to-run-an-ab-test.html">
                How Not to Run an A/B Test
              </a>
              &nbsp;(Evan Miller&apos;s blog)
            </li>
            <li>
              <a href="https://www.amazon.com/All-Statistics-Statistical-Inference-Springer/dp/1441923225">
                All of Statistics
              </a>
              &nbsp;by Larry Wasserman. An accessible statistics textbook that discusses
              causal inference and experiment design.
            </li>
            <li>
              <a href="https://www.wiley.com/en-us/Categorical+Data+Analysis%2C+3rd+Edition-p-9780470463635">
                Categorical Data Analysis
              </a>
              &nbsp;by Alan Agresti. An excellent discussion of the analysis of
              contingency tables.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default AnalysisFAQ;
